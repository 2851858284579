
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import {
  apiDeviceList,
  apiDeviceDel,
  apiDevicePreview,
  apiDeviceImport,
  getDeviceSizeApi,
  getDeviceSupplierApi
} from '@/api/imagetru3d'

import * as XLXS from 'xlsx'
/* eslint-disable */
@Component({
  components: {
    LsDialog
  }
})
export default class Device extends Vue {
  $refs!: { plTreeTable: any; addDevicePreview: any }
  pager = new RequestPaging()

  QRCodeImageUrl = ''
  fit = 'fill'
  pageForm={
    total: 10,
    pageSize: 10,
    currentPage: 1
  }
  //搜索字段
  keyword = ""
  sizeList = []
  supplierList = []
  is_active = '';
  supplier_name = '';
  device_size = '';
  is_search = 0;

  dialogFormVisible = false

  form= {
    name: '',
    region: '',
    date1: '',
    date2: '',
    delivery: false,
    type: [],
    resource: '',
    desc: ''
  }

  upload_file='' // 读取文件参数
  xlsxJson:any
  wb:any
  fullscreenLoading:boolean =  false //load全局

        // 分页事件
  handlePageSize ({ page, size }:{page:any, size:any}) { // 申明类型为any
          // 拼接参数，发送请求
          this.pageForm.currentPage = page
          this.pageForm.pageSize = size
          this.getDeviceList()
        }

        // 设备列表
  getDeviceList (): void {

    if(isNaN(this.pageForm.currentPage)){
      this.pageForm.currentPage = 1
    }
    if(isNaN(this.pageForm.pageSize)){
      this.pageForm.pageSize = 10
    }
        if(this.pageForm.currentPage)
          this.pager
            .request({
              callback: apiDeviceList,
              params: {
                pager_type: 1,
                page: this.pageForm.currentPage,
                size: this.pageForm.pageSize,
                is_search: this.is_search,//搜索数据开始
                keyword: this.keyword,
                device_size: this.device_size,
                is_active: this.is_active,
                supplier_name: this.supplier_name,
              }
            })
            .then((res) => {
              this.pageForm.total = res.count
              this.pageForm.pageSize = parseInt(res.size)
              this.pageForm.currentPage = parseInt(res.page)
            })
        }

  handlePreview (id: number) {
          apiDevicePreview(id).then((res) => {
            this.QRCodeImageUrl = res.img_url
            console.log(this.QRCodeImageUrl)
          })
        }

  handleDelete (id: number) {
          apiDeviceDel(id).then(() => {
            this.getDeviceList()
          })
        }

// 获取设备尺寸
  getDeviceSize () {
    // 请求数据

    getDeviceSizeApi().then((resp:any) => {
      this.sizeList = resp
    })
  }

  getDeviceSupplier () {
    // 请求数据

    getDeviceSupplierApi().then((resp:any) => {
      this.supplierList = resp
    })
  }

  created () {
          this.getDeviceList()
          this.getDeviceSize()
          this.getDeviceSupplier()
        }

  // 弹出模板方法
  openImport () {
          // 注意路径，前面不要加 ./ 会形成相对路径，点击下载不了。
          window.open('/file/empty_tpl.xlsx?times='+(Math.floor(Math.random() * 100) + 1), '_blank')
        }

  // 读取excel文件内容并拼接数据发送
  importExcel (file:File) {
          //开启loading

          const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

          // let file = file.files[0] // 使用传统的input方法需要加上这一步
          const types = file.name.split('.')[1]
          const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types)
          if (!fileType) {
            loading.close();//关闭loading
            alert('格式错误！请重新选择')
            return
          }

          this.file2Xce(file).then(tabJson => {

            if (tabJson) {
              this.xlsxJson = tabJson

              var res = this.excelFormData()
              //发送数据，进行后端传输

              var postData = { //待发送数据
                data:res,
              };

              //发送json数据到后端，进行批量导入
              apiDeviceImport(postData).then((resp)=>{
                loading.close();//关闭loading
                console.log(resp)
                this.getDeviceList()
              }).catch((err:any)=>{
                loading.close();//关闭loading
                console.log(err)
                this.getDeviceList()
              });
              //暂停1s
              loading.close();//关闭loading

            }
          })
        }

  // 处理excel表单数据
  file2Xce (file: any) {
          var that = this
          return new Promise(function (resolve, _reject) {
            const reader = new FileReader()
            reader.onload = function (e) {
              const data = e.target?.result
              that.wb = XLXS.read(data, {
                type: 'binary'
              })
              const result:any = []
              that.wb.SheetNames.forEach((sheetName: string | number) => {
                result.push({
                  sheetName: sheetName,
                  sheet: XLXS.utils.sheet_to_json(that.wb.Sheets[sheetName])
                })
              })
              resolve(result)
            }
            reader.readAsBinaryString(file.raw)
            // reader.readAsBinaryString(file) // 传统input方法
          })
        }

  /*
        *  整理上传的数据格式
        *
        * */
  excelFormData () {
          // excel的表
          const mapInfo:any = [];
        /*  mapInfo['设备名称'] = 'device_name';*/
          mapInfo['设备编号'] = 'device_number';
          mapInfo['设备尺寸'] = 'device_size';
          mapInfo['备注(非必填)'] = 'note';
          mapInfo['参数编码(使用填写模板名称)775-825'] = 'device_tpl';
          mapInfo['偏移值'] = 'shift';
          mapInfo['颜色'] = 'color';
          mapInfo['生产厂家'] = 'supplier_name';
          mapInfo['出厂日期'] = 'supplier_time';
          mapInfo['所属机构'] = 'merchant_mobile';
           var jsonData:any = [];
           if(this.xlsxJson[0].sheet.length == 0){
             alert('导入数据不能为空!')
             return  false;
           }
          this.xlsxJson[0].sheet.map((_obj: any) => {

            // 存储对象每个键为一个数组
            const zhKeys = Object.keys(_obj)
            var tmp:any = {};
            zhKeys.forEach((zhkey: any) => {

               const enKey:any = mapInfo[zhkey]
               if(enKey == 'undefind'){
                alert('缺少内容字段')
                  return false;
               }

              tmp[enKey] = _obj[zhkey];//key=>value封装
            })

            jsonData.push(tmp);
          })

          return jsonData;
        }

   //搜索查询
  searchData()
  {
    this.is_search = 1;
    this.getDeviceList()
  }

  resetSearchData()
  {
      this.is_search = 0;
      this.keyword = '';
      this.device_size = '',
         this.is_active = '',
       this.supplier_name = '',
    this.getDeviceList()
  }
}
